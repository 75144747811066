// import classNames from "classnames";
import React from "react";
// import * as styles from "./Intro.module.scss";

const Intro = ({ children }) => {
  return (
    <section className="section-dark">
      <div className="section wrapper">{children}</div>
    </section>
  );
};

export default Intro;
