import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import * as styles from "./HomeService.module.scss";
import { servicesURL } from "../../config/url";

const Services = [
  {
    name: "Website Design",
    desc: "WordPress, Headless CMS, Custom Design",
    url: servicesURL.webDesign,
    img: (
      <StaticImage
        src="../../images/home-website.jpg"
        alt="Web Design"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Ecommerce Setup",
    desc: "Payment Gateway, Billing System Integration",
    url: servicesURL.ecommerce,
    img: (
      <StaticImage
        src="../../images/home-ecommerce.jpg"
        alt="Ecommerce"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Software Development",
    desc: "Gated Content, API Integration, Cloud Solutions",
    url: servicesURL.software,
    img: (
      <StaticImage
        src="../../images/home-software.jpg"
        alt="Software"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Mobile Apps Development",
    desc: "UI/UX Designs, iOS and Android Development",
    url: servicesURL.apps,
    img: (
      <StaticImage
        src="../../images/home-app.jpg"
        alt="UI UX"
        className={styles["service_image"]}
      />
    ),
  },
];

const HomeService = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const onMouseOver = (index) => {
    setHoverIndex(index);
    setActiveIndex(-1);
    resetTimeout();
  };
  const onMouseLeave = () => {
    setHoverIndex(-1);
    setActiveIndex(0);
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setActiveIndex((prevIndex) =>
          prevIndex === Services.length - 1 ? 0 : prevIndex + 1
        ),
      6000
    );

    return () => resetTimeout();
  }, [activeIndex]);

  const renderedItems = Services.map((item, idx) => {
    const active = idx === activeIndex ? "active" : "";
    const hover = idx === hoverIndex ? "hover" : "";

    return (
      <div
        className={classNames(
          styles["item"],
          active && styles["active"],
          hover && styles["hover"]
        )}
        role="button"
        tabIndex="0"
        key={idx}
      >
        <Link
          to={item.url}
          className={styles["info"]}
          onMouseOver={() => onMouseOver(idx)}
          onMouseLeave={() => onMouseLeave(idx)}
        >
          <h3 className="h3">{item.name}</h3>
          <p>{item.desc}</p>
        </Link>
        <div className={styles["image"]}>{item.img}</div>
      </div>
    );
  });

  return (
    <section className="section wrapper">
      <div className="title">Services</div>
      {renderedItems}
    </section>
  );
};

export default HomeService;
