import React, { useState, useEffect, useRef } from "react"; // { useEffect, useRef }
import classNames from "classnames";
import * as styles from "./HomeSlider.module.scss";
import ScrollReveal from "../../components/layout/ScrollReveal";
import { StaticImage } from "gatsby-plugin-image";
import { externalURL } from "../../config/url";

const sliderContent = [
  {
    category: "Software & Mobile App",
    content: "A booking app that helps improve business productivity",
    url: externalURL.addpointment,
    image: <StaticImage src="../../images/slider-addpointment.jpg" alt="" />,
  },
  {
    category: "Ecommerce",
    content:
      "A brand new ecommerce marketplace that gathers various brands and styles under one roof",
    url: externalURL.moodboard,
    image: <StaticImage src="../../images/slider-moodboard.jpg" alt="" />,
  },
  {
    category: "Website & Branding",
    content:
      "A real-estate branding website for a one-of-a-kind urban HOPSCA city",
    url: externalURL.lakecity,
    image: <StaticImage src="../../images/slider-lakecity.jpg" alt="" />,
  },
  {
    category: "Ecommerce",
    content:
      "An ecommerce website for a company that provides sanitation and eco-friendly low carbon solutions",
    url: externalURL.esona,
    image: <StaticImage src="../../images/slider-esona.jpg" alt="" />,
  },
];

const HomeSlider = () => {
  const totalSlide = sliderContent.length;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [clickDisabled, setClickDisabled] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentSlide((prevSlide) =>
          prevSlide === sliderContent.length - 1 ? 0 : prevSlide + 1
        ),
      6000
    );
    return () => resetTimeout();
  }, [currentSlide]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  function handleNav(nav) {
    if (!clickDisabled) {
      setClickDisabled(true);
      if (nav === "next")
        setCurrentSlide(currentSlide === totalSlide - 1 ? 0 : currentSlide + 1);
      else
        setCurrentSlide(currentSlide === 0 ? totalSlide - 1 : currentSlide - 1);
      setTimeout(() => setClickDisabled(false), 1500);
      resetTimeout();
    }
  }
  function getSlideStack(stack) {
    if (stack === "next")
      return currentSlide === totalSlide - 1 ? 0 : currentSlide + 1;
    else return currentSlide === 0 ? totalSlide - 1 : currentSlide - 1;
  }
  return (
    <section className={styles["section"]}>
      <div className={`title ${styles["title"]}`}>Project</div>
      <ScrollReveal effect="slide-up" delay={100}>
        <div className={styles["container"]}>
          <div className={styles["content_bg"]}></div>
          <div className={styles["slider"]}>
            {sliderContent.map((item, index) => {
              const nextSlide = getSlideStack("next");
              const prevSlide = getSlideStack("prev");
              return (
                <React.Fragment key={index}>
                  <div
                    className={classNames(
                      styles["slide"],
                      index === nextSlide && styles["slide_next"],
                      index === currentSlide && styles["slide_current"],
                      index === prevSlide && styles["slide_prev"]
                    )}
                  >
                    {item.image}
                  </div>
                  <div
                    className={classNames(
                      styles["content"],
                      index === nextSlide && styles["content_next"],
                      index === currentSlide && styles["content_current"],
                      index === prevSlide && styles["content_prev"]
                    )}
                  >
                    <p className="h6">{item.category}</p>
                    <h3 className="h4">{item.content}</h3>
                    <a
                      href={item.url}
                      rel="noreferrer"
                      target="_blank"
                      className="btn"
                    >
                      View Project
                    </a>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <button
            onClick={() => handleNav("next")}
            className={`btn ${styles["btn_nav"]} ${styles["btn_next"]}`}
          >
            <span>Next</span>
          </button>
          <button
            onClick={() => handleNav("back")}
            className={`btn ${styles["btn_nav"]} ${styles["btn_back"]}`}
          >
            <span>Back</span>
          </button>
        </div>
      </ScrollReveal>
    </section>
  );
};

export default HomeSlider;
