// extracted by mini-css-extract-plugin
export var btn_back = "HomeSlider-module--btn_back--nY3fB";
export var btn_nav = "HomeSlider-module--btn_nav--jCRCY";
export var btn_next = "HomeSlider-module--btn_next--Vg3F1";
export var container = "HomeSlider-module--container--W6nhT";
export var content = "HomeSlider-module--content--XSuYB";
export var content_bg = "HomeSlider-module--content_bg--ySvKV";
export var content_current = "HomeSlider-module--content_current--ie87N";
export var content_next = "HomeSlider-module--content_next--8TnAY";
export var content_prev = "HomeSlider-module--content_prev--xjyEg";
export var section = "HomeSlider-module--section--wdjd7";
export var slide = "HomeSlider-module--slide--hPT8w";
export var slide_current = "HomeSlider-module--slide_current--v8NLd";
export var slide_next = "HomeSlider-module--slide_next--AjOsL";
export var slide_prev = "HomeSlider-module--slide_prev--bTVpW";
export var slider = "HomeSlider-module--slider--gTGkE";
export var title = "HomeSlider-module--title--7BFCa";